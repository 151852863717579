import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Generate Order Data
function createData(id, name, property, unit, email, phone) {
  return { id, name, property, unit, email, phone };
}

const rows = [
  createData(0, 'Elvis Presley', 'Tupelo, MS', 'A', 'email@email', '999-999-9999'),
  createData(1, 'Paul McCartney', 'London, UK', '1', 'email@email', '999-999-9999'),
  createData(2, 'Tom Scholz', 'Boston, MA', '305', 'email@email', '999-999-9999'),
  createData(3, 'Michael Jackson', 'Gary, IN', '', 'email@email', '999-999-9999'),
  createData(4, 'Bruce Springsteen', 'Long Branch, NJ', '12', 'email@email', '999-999-9999'),
];

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function VendorsGrid() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Property</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell align="right">Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.unit}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell align="right">{row.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}