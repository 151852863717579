import React from "react";
import { Route } from "react-router-dom";
import PublicButtonAppBar from "../components/public/PublicAppBar";

export const PublicRoute  = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <div>
            <PublicButtonAppBar></PublicButtonAppBar>
            <Component {...props} />
        </div>
    );

    return <Route {...rest} render={renderFn(component)} />;
};

