import React, {Component} from 'react';

export default class Services extends Component {
    render() {
        return (
            <div>
                Services!!!
            </div>
        )
    }
}