import * as React from "react";
import { Route, Switch } from "react-router-dom";


import { Callback } from "../components/auth/callback";
import { Logout } from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
import { PrivateRoute } from "./privateRoute";
import { Register } from "../components/auth/register";
import { SilentRenew } from "../components/auth/silentRenew";
import Dashboard from "../components/dashboard/Dashboard";
import Properties from "../components/properties/properties";
import Tenants from "../components/tenants/tenants";
import Vendors from "../components/vendors/vendors";
import Company from "../components/public/Company";
import Services from "../components/public/Services";
import PropertyManagement from "../components/public/PropertyManagement";
import { PublicRoute } from "./publicRoute";
import Home from "../home/Home";

export const Routes = (
    <Switch>
        <PublicRoute path="/" component={Home} />
    </Switch>
);