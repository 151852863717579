import React, {Component} from 'react';

export default class Company extends Component {
    componentDidMount() {
        
        var xmlhttp = new XMLHttpRequest();
          xmlhttp.open("GET", "https://localhost:6001/api/Company");
          xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
          xmlhttp.send("{username:'nlackman', password:'aaasssnnn'}");
      }

    render() {
        return (
            <div>Company!!!</div>
        )
    }
}