
import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../common/Title';

// Generate Order Data
function createData(id, date, name, property, unit, amount) {
  return { id, date, name, property, unit, amount };
}

const rows = [
  createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'A', 1400),
  createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', '1', 3200),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', '305', 850),
  createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', '', 1100),
  createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', '12', 1635),
];

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function PropertyGrid() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Rent Payments</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Due Next</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Property</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.unit}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="javascript:;">
          See more orders
        </Link>
      </div>
    </React.Fragment>
  );
}