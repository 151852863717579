import React, {Component} from 'react';
import '../home/Home.css';
import { Grid } from '@material-ui/core';

class Home extends Component {
    render() {
        return (
            <Grid container justify="center">
                <Grid xs={12} lg={4}>
                    <h1>In progress, come back later!</h1>
                </Grid>
            </Grid>
        )
    }
    
}
export default Home;