export const IDENTITY_CONFIG = {
    authority: "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_RqeBahhmF", //(string): The URL of the OIDC provider.
    client_id: "l72bs385b7c9vjp2sc0bocdcr", //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: "https://parkave.rentals/callback", //The URI of your client application to receive a response from the OIDC provider.
    login: "https://parkave.rentals/login",
    automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: "", //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: "https://parkave.rentals/logout/callback", // (string): The OIDC post-logout redirect URI.
    audience: "l72bs385b7c9vjp2sc0bocdcr", //is there a way to specific the audience when making the jwt
    response_type: "token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    grantType: "code",
    scope: "profile email", //(string, default: 'openid'): The scope being requested from the OIDC provider.
    webAuthResponseType: "id_token token"
};

export const METADATA_OIDC = {
    issuer: "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_RqeBahhmF",
    jwks_uri: "https://auth.parkave.rentals/.well-known/openid-configuration/jwks",
    authorization_endpoint: "https://auth.parkave.rentals/login",
    token_endpoint: "https://auth.parkave.rentals/connect/token",
    userinfo_endpoint: "https://auth.parkave.rentals/connect/userinfo",
    end_session_endpoint: "https://auth.parkave.rentals/connect/endsession",
    check_session_iframe: "https://auth.parkave.rentals/connect/checksession",
    revocation_endpoint: "https://auth.parkave.rentals/connect/revocation",
    introspection_endpoint: "https://auth.parkave.rentals/connect/introspect"
};